import { useEffect, type FC } from 'react'

import { useChoicesContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { SelectInput } from 'components'
import { perPageLimit, ReferenceInput, requiredValidation } from 'core'
import { useResources } from 'resources/utils'

import { type UnitStatusModel } from '../types'
import { unitStatusResource } from '../utils'

import StatusField from './StatusField'

interface StatusInputProps {
    disabled?: boolean
    source?: string
    label?: string
    helperText?: string
    withDefaultValue?: boolean
}

const StatusInput: FC<StatusInputProps> = ({
    source = 'status',
    label = 'Status',
    withDefaultValue,
    ...rest
}) => {
    const { unitStatus } = useResources()

    if (!unitStatus.show) {
        return null
    }

    return (
        <ReferenceInput
            source={source}
            reference={unitStatusResource.resource}
            perPage={perPageLimit}
        >
            <>
                <SelectInput
                    optionText={optionText}
                    label={label}
                    validate={requiredValidation}
                    {...rest}
                />
                {withDefaultValue ? <DefaultValueHandler source={source} /> : null}
            </>
        </ReferenceInput>
    )
}

const optionText = (option) => <StatusField record={option as UnitStatusModel} />

export default StatusInput

const DefaultValueHandler = ({ source }: { source: string }) => {
    const { availableChoices, isLoading } = useChoicesContext()
    const { getValues, setValue } = useFormContext()

    useEffect(() => {
        if (getValues('statusDefaultSet') || isLoading) {
            return
        }
        setValue('statusDefaultSet', true)
        setValue(source, availableChoices.find((status: UnitStatusModel) => status.isDefault).id)
    }, [isLoading])

    return null
}
