import { type FC } from 'react'

import { InfoCard, ViewHeader, InfoCardHeader, InfoCardDetails } from 'components'
import { useRecordContext } from 'core'
import { formatDate } from 'lib'
import { formatPOItemTotal } from 'pages/PurchaseOrders/LineItems/utils'
import { PurchaseInvoiceCard } from 'resources/purchaseInvoices'
import { poResource, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { Box, InfoBadge, Section, GridContainerColumns, GridItemLayout, PageContent } from 'ui'

const ReturnItemsClosedInfo: FC = () => {
    const record = useRecordContext<PurchaseOrderModel>()
    const invoice = record.invoiceData[0]

    return (
        <PageContent
            pb="0 !important"
            flexGrow="0 !important"
            zIndex={2}
        >
            <GridContainerColumns>
                <GridItemLayout>
                    <Section>
                        <ViewHeader title="Return Items">
                            <InfoBadge
                                badgeContent={
                                    'Closed on date ' +
                                    formatDate(record.closedOn, 'MMM dd yyyy h:mm aaa')
                                }
                            />
                        </ViewHeader>
                        <InfoCard sx={{ mb: '28px' }}>
                            <InfoCardHeader title="Financial Summary" />
                            <InfoCardDetails
                                details={[
                                    {
                                        label: 'Parts total',
                                        source: () => formatPOItemTotal(record.total),
                                    },
                                ]}
                            />
                        </InfoCard>
                        <Box height="202px">
                            <PurchaseInvoiceCard
                                invoice={invoice}
                                editOnly
                                resource={poResource.invoices(record.id)}
                                disableActions
                            />
                        </Box>
                    </Section>
                </GridItemLayout>
            </GridContainerColumns>
        </PageContent>
    )
}

export default ReturnItemsClosedInfo
