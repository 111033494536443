import { type ReactElement, useEffect, useState } from 'react'

import { differenceInCalendarDays } from 'date-fns'
import { useListContext, useResourceContext, useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    CollapsibleContent,
    CollapsibleIconHolder,
    CollapsibleInfoCard,
    DistanceLabel,
    InfoCardHeader,
    resolveIntegerSpacedMask,
} from 'components'
import { serialize, useConfirm, useNotify } from 'core'
import { type MenuItemActions, deleteOneAction } from 'core/actions'
import api from 'core/api'
import { formatDate, globalClassNames, type Theme } from 'lib'
import {
    AttachmentsCollapsibleContent,
    AttachmentsCollapsibleIconHolder,
} from 'resources/attachments'
import {
    useUnitPMDrawerEditor,
    getPmTypeChoice,
    type PMIntervalTypes,
    type PmModel,
    deleteUnitPMIntervalConfig,
    LineSlider,
} from 'resources/pm'
import { isUnitArchived, type MeterModel, type UnitModel } from 'resources/units'
import { ck33Fields } from 'resources/vmrs'
import { Divider, Anchor, SvgIcon, DataAvatar, Typography, BoxContainer, IconBox } from 'ui'
import { pathJoin } from 'utils'

import BottomSection from './BottomSection'
import CollapsibleNotes from './CollapsibleNotes'
import MeterTypeSelectionButton from './MeterTypeSelectionButton'
import ResetPmContent from './ResetPmContent'
import ServiceSection from './ServiceSection'

export interface DataType {
    type: MeterModel['type']
    every: number
    lastDone: number
    value: number
    color?: string
    total: number
    threshold?: number
    image?: ReactElement
    note?: string
}

export const getImage = (status: PmModel['mostUrgentStatus']) => {
    if (status === 'PLANNED') {
        return Icons.EventAvailableOutlined
    }
    if (status === 'OVERDUE') {
        return Icons.CalendarAlertOutlined
    }
    return Icons.CalendarClockOutlined
}
const tooltipValueFormatter = (value: number, type: PMIntervalTypes): string | ReactElement => {
    if (type === 'ENGINE_HOURS' || type === 'APU_HOURS') {
        return `${resolveIntegerSpacedMask(value)} h`
    }
    return (
        <>
            {resolveIntegerSpacedMask(value)} <DistanceLabel variant="abbr" />
        </>
    )
}
export const getColor = (status: PmModel['mostUrgentStatus'], isArchived?: boolean) => {
    if (isArchived) {
        return (theme: Theme) => theme.palette.action.disabled
    }
    if (status === 'PLANNED') {
        return (theme: Theme) => theme.palette.success.main
    }
    if (status === 'OVERDUE') {
        return (theme: Theme) => theme.palette.error.main
    }
    return (theme: Theme) => theme.palette.charts.orange
}
const UnitPMCard = ({ unitPmRecord }: { unitPmRecord: PmModel }) => {
    const { record } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(record)
    const [selectedPM, setSelectedPM] = useState(() =>
        unitPmRecord.mostUrgentType
            ? unitPmRecord.intervals.find((item) => item.type === unitPmRecord.mostUrgentType)
            : unitPmRecord.intervals[0],
    )

    const resource = useResourceContext()
    const confirm = useConfirm()
    const { data } = useListContext<PmModel>()
    const notify = useNotify()
    const getThresholdValue = () => {
        if (selectedPM.threshold === null) {
            return 100
        }
        if (selectedPM.type === 'TIME') {
            return (
                ((selectedPM.threshold * getPmTypeChoice(selectedPM.thresholdType).toDay) /
                    (selectedPM.value * getPmTypeChoice(selectedPM.valueType).toDay)) *
                100
            )
        }
        return (selectedPM.threshold / selectedPM.value) * 100
    }
    useEffect(
        () =>
            setSelectedPM(() =>
                unitPmRecord.intervals.find((item) => item.type === selectedPM.type),
            ),
        [unitPmRecord],
    )
    const currentValue =
        (selectedPM.passed /
            (selectedPM.type === 'TIME'
                ? Math.abs(
                      differenceInCalendarDays(
                          new Date(unitPmRecord.lastDone),
                          new Date(selectedPM.overdue),
                      ),
                  )
                : selectedPM.value)) *
        100

    const current = 100 - getThresholdValue()
    const edit = useUnitPMDrawerEditor()

    const editPM = () => edit({ id: unitPmRecord.id, type: 'dependent' })
    const Icon = Icons.Options

    const actions: MenuItemActions<any, {}> = isArchived
        ? undefined
        : (args, { children }) => [
              children({
                  Icon: Icons.Edit,
                  title: 'Edit',
                  key: 'edit',
                  onClick: editPM,
              }),
              children({
                  Icon: Icons.RestoreOutlined,
                  title: 'Reset',
                  key: 'reset',
                  onClick: () => {
                      confirm({
                          title: 'Are you sure you want to reset this interval?',
                          closeOnError: false,
                          content: (
                              <ResetPmContent
                                  data={data}
                                  record={record}
                                  unitPmRecord={unitPmRecord}
                              />
                          ),
                          onConfirm: async ({ formValues }) => {
                              const { lastDone, ...inputValues } = formValues
                              const data = serialize({ ...formValues }, [
                                  { name: 'lastDone', parse: 'dateTime' },
                                  ...Object.keys(inputValues),
                              ])
                              await api.post(
                                  pathJoin(resource, String(unitPmRecord.id), 'reset'),
                                  data,
                              )
                              notify('PM Reset Successfully!')
                          },
                          confirmButtonProps: {
                              children: 'Reset',
                              startIcon: <Icons.RestoreOutlined />,
                          },
                          awaitOnConfirm: true,
                      })
                  },
              }),
              deleteOneAction({
                  children,
                  ...deleteUnitPMIntervalConfig,
                  id: unitPmRecord.id,
              }),
          ]

    return (
        <CollapsibleInfoCard sameParent>
            <InfoCardHeader
                avatar={
                    <DataAvatar
                        defaultImage={
                            <SvgIcon
                                component={getImage(unitPmRecord.mostUrgentStatus)}
                                sx={(theme) => ({
                                    color: getColor(unitPmRecord.mostUrgentStatus, isArchived),
                                })}
                            />
                        }
                        color={getColor(unitPmRecord.mostUrgentStatus, isArchived)}
                    />
                }
                title={
                    <Anchor
                        className={globalClassNames.ellipsis}
                        onClick={editPM}
                        display="block"
                    >
                        {unitPmRecord.name}
                    </Anchor>
                }
                subheader={
                    <Typography
                        variant="chartsBody"
                        className={globalClassNames.ellipsis}
                    >
                        {ck33Fields.self.value(unitPmRecord.componentData)}
                    </Typography>
                }
                action={
                    isArchived ? (
                        <IconBox onClick={editPM}>
                            <Icon />
                        </IconBox>
                    ) : undefined
                }
                actions={actions}
            />
            <MeterTypeSelectionButton
                selectedPM={selectedPM}
                setSelectedPM={setSelectedPM}
                unitPmRecord={unitPmRecord.intervals}
            />
            <ServiceSection
                selectedPM={selectedPM}
                unitPmRecord={unitPmRecord}
            />
            <LineSlider
                value={current}
                bgColor={getColor(selectedPM.status, isArchived)}
                lines={[
                    {
                        bgColor: getColor(selectedPM.status, isArchived),
                        width: currentValue > 100 ? 100 : currentValue,
                        tooltip: isArchived ? null : selectedPM.type === 'TIME' ? (
                            `${formatDate(
                                selectedPM.now,
                                (dateFormats) => dateFormats.shortenedDate,
                            )} - ${selectedPM.passedPercentTillOverdue}%`
                        ) : (
                            <>
                                {tooltipValueFormatter(selectedPM.now, selectedPM.type)} -{' '}
                                {Math.round(currentValue)}%
                            </>
                        ),
                    },
                ]}
            />
            <BottomSection
                isArchived={isArchived}
                selectedPM={selectedPM}
                pmReminder={unitPmRecord}
            />

            <Divider sx={{ my: '10px' }} />
            <BoxContainer gap="8px">
                <CollapsibleIconHolder
                    name="notes"
                    title="Notes"
                >
                    <Icons.CommentOutlined fontSize="small" />
                </CollapsibleIconHolder>
                <AttachmentsCollapsibleIconHolder record={unitPmRecord} />
            </BoxContainer>
            <CollapsibleContent name="notes">
                <CollapsibleNotes note={unitPmRecord.description} />
            </CollapsibleContent>
            <AttachmentsCollapsibleContent
                disabled={isArchived}
                titleOnDisabled="Unarchive the unit to add document"
                uploadResource={resource}
                record={unitPmRecord}
            />
        </CollapsibleInfoCard>
    )
}

export default UnitPMCard
