import { type BaseModel } from 'appTypes'
import { type Serializer } from 'core'

import { type Attachments } from './types'

export const getAttachmentName = (index: number, source?: string) =>
    `${source ?? 'attachment'}${index}`

export const getRecordAttachments = (
    record: BaseModel,
    source?: string,
    formValues?: any,
): {
    files: Attachments
    count: number
} => {
    const attachments = record || formValues
    const finalSource = source ?? 'attachment'
    const files = {}
    let count = 0
    for (let i = 0; i <= 9; i += 1) {
        if (attachments && attachments[finalSource + i]) {
            count += 1
        }
        files[finalSource + i] = attachments ? attachments[finalSource + i] : null
    }
    return { files, count }
}

export const attachmentsToArray = (attachments: Attachments) =>
    Object.keys(attachments).map((key) => ({
        id: key,
        file: attachments[key],
    }))

export const MAX_FILE_COUNT = 10

export const attachmentsIndexes = Array.from({ length: MAX_FILE_COUNT }, (_, i) => i)

export const attachmentsSerializer: Serializer = attachmentsIndexes.map((index) => ({
    name: getAttachmentName(index),
    parse: 'file',
}))
