import { useRecordContext } from 'react-admin'

import { type BaseModel } from 'appTypes'
import Icons from 'assets/icons'
import { CollapsibleIconHolder } from 'components'

import { getRecordAttachments } from '../utils'

const AttachmentsCollapsibleIconHolder = ({
    title = 'Documents',
    record: recordProp,
    disabled,
    source,
    titleOnDisabled,
    disableWhenNoAttachments: disableWhenNoAttachmentsProp,
}: {
    title?: string
    record?: BaseModel
    disabled?: boolean
    source?: string
    titleOnDisabled?: string
    disableWhenNoAttachments?: boolean
}) => {
    const record = useRecordContext<BaseModel>()
    const finalRecord = recordProp ?? record
    const attachments = getRecordAttachments(finalRecord, source)

    const disableWhenNoAttachments = disableWhenNoAttachmentsProp && !attachments.count
    const noDataLabel = `No ${title} Added`

    return (
        <CollapsibleIconHolder
            name="attachments"
            title={attachments.count ? `${title} (${attachments.count})` : noDataLabel}
            disabled={disabled || disableWhenNoAttachments}
            titleOnDisabled={disableWhenNoAttachments ? noDataLabel : titleOnDisabled}
            key="attachmentsIcon"
        >
            <Icons.AttachFileOutlined fontSize="small" />
        </CollapsibleIconHolder>
    )
}

export default AttachmentsCollapsibleIconHolder
