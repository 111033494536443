import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { type BaseModel } from 'appTypes'
import { CollapsibleContent } from 'components'
import {
    api,
    getFileExtensionData,
    serialize,
    useFinalErrorHandler,
    useNotify,
    useResource,
} from 'core'
import { globalClassNames } from 'lib'
import { maxFileSize } from 'resources/gallery'
import {
    Alert,
    BoxContainer,
    IconElement,
    InfoBadge,
    NonDisplayedInput,
    SimpleScrollbar,
    Stack,
    Tooltip,
    Typography,
} from 'ui'
import { pathJoin } from 'utils'

import {
    attachmentsToArray,
    getAttachmentName,
    getRecordAttachments,
    MAX_FILE_COUNT,
} from '../utils'

import { AttachmentButton } from './AttachmentsFormInput'

interface Props {
    title?: string
    record?: BaseModel
    uploadResource?: string
    disabled?: boolean
    source?: string
    titleOnDisabled?: string
}

const AttachmentsCollapsibleContent: FC<Props> = ({
    title = 'Documents',
    record: recordProp,
    uploadResource,
    disabled,
    source,
    titleOnDisabled,
}) => {
    const record = useRecordContext<BaseModel>()
    const finalRecord = recordProp ?? record
    const attachments = getRecordAttachments(finalRecord, source)
    const files = attachmentsToArray(attachments.files)
    const resource = useResource()
    const finalResource = uploadResource ?? resource.resource

    return (
        <CollapsibleContent name="attachments">
            <Stack mt="15px">
                <BoxContainer justifyContent="space-between">
                    <Typography
                        gap="8px"
                        component={BoxContainer}
                        variant="chartTitle"
                        mb="10px"
                    >
                        {title}
                        <InfoBadge badgeContent={String(attachments.count)} />
                    </Typography>
                    <UploadButton
                        source={source}
                        disabled={disabled}
                        count={attachments.count}
                        files={files}
                        uploadResource={pathJoin(finalResource, finalRecord.id)}
                        titleOnDisabled={titleOnDisabled}
                    />
                </BoxContainer>
                <SimpleScrollbar style={{ maxHeight: '184px' }}>
                    {attachments.count ? (
                        files.map(({ id, file }) => {
                            if (!file) {
                                return null
                            }

                            const { Icon: FileIcon, color } = getFileExtensionData(file)
                            return (
                                <BoxContainer
                                    key={id}
                                    mt="10px"
                                    sx={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <IconElement
                                        component={FileIcon}
                                        color={color}
                                        size="22px"
                                    />
                                    <Typography
                                        component="a"
                                        href={file}
                                        target="_blank"
                                        rel="noreferrer"
                                        variant="body2"
                                        ml="10px"
                                        color="primary.main"
                                        py="8px"
                                        className={globalClassNames.ellipsis}
                                    >
                                        {file.split('?')[0].split('/').pop()}
                                    </Typography>
                                </BoxContainer>
                            )
                        })
                    ) : (
                        <Alert
                            severity="info"
                            sx={{ mt: '10px' }}
                        >
                            No {title} Added
                        </Alert>
                    )}
                </SimpleScrollbar>
            </Stack>
        </CollapsibleContent>
    )
}

export default AttachmentsCollapsibleContent

const UploadButton: FC<{
    count: number
    files: {
        id: string
        file: any
    }[]
    uploadResource: string
    disabled: boolean
    source: string
    titleOnDisabled?: string
}> = ({ count, files, uploadResource, disabled: disabledProp, titleOnDisabled, source }) => {
    const notify = useNotify()

    const errorHandler = useFinalErrorHandler()
    const generalErrorHandler = (e, id: string) => {
        if (e[id]) {
            notify({
                title: e[id].message,
                type: 'error',
            })
        } else {
            errorHandler(e)
        }
    }

    const addAttachment = async (index: number, file: string | File) => {
        let id = getAttachmentName(index, source)
        if (typeof index === 'undefined') {
            id = files.find((data) => !data.file).id
        }
        const data = serialize({ [id]: file }, [{ name: id, parse: 'file' }])
        try {
            await api.patch(uploadResource, data)
        } catch (e) {
            generalErrorHandler(e, id)
        }
    }
    const upload = async (event, index?: number) => {
        const file: File = event.target.files?.[0]
        if (!file) {
            return
        }

        if (file.size > maxFileSize.size) {
            notify({
                title: maxFileSize.errorMessage,
                type: 'error',
            })
        } else {
            addAttachment(index, file)
        }
        event.target.value = null
    }

    const disabled = disabledProp || count >= MAX_FILE_COUNT
    const button = <AttachmentButton disabled={disabled}>Upload</AttachmentButton>

    if (disabled) {
        return (
            <Tooltip
                title={
                    disabledProp && titleOnDisabled
                        ? titleOnDisabled
                        : `Maximum ${MAX_FILE_COUNT} files`
                }
            >
                <span>{button}</span>
            </Tooltip>
        )
    }

    return (
        <label>
            <NonDisplayedInput
                type="file"
                onChange={upload}
            />
            <Tooltip title={`Add up to ${MAX_FILE_COUNT} files, max size 30 MB each`}>
                <span>{button}</span>
            </Tooltip>
        </label>
    )
}
